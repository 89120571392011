/**
 * nodeChart.js
 * Created by AGROINTELLIGENT\d.monfort on 1/04/22.
 */


/**
 * Función que devuelve colores aleatorios dada una semilla.
 * @param semillaColor
 * @returns {{borderColor: string, backgroundColor: string}}
 */
function generarColoresParaGrafico(semillaColor = null) {

    //Genera los colores aletorios
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    if (semillaColor != null) {
        // Extrae los componentes de color de la semilla
        const redSeed = parseInt(semillaColor.slice(1, 3), 16);
        const greenSeed = parseInt(semillaColor.slice(3, 5), 16);
        const blueSeed = parseInt(semillaColor.slice(5, 7), 16);

        // Genera componentes de color aleatorios basados en la semilla
        const red = (redSeed + Math.floor(Math.random() * 128)) % 256;
        const green = (greenSeed + Math.floor(Math.random() * 128)) % 256;
        const blue = (blueSeed + Math.floor(Math.random() * 128)) % 256;
    }


    // Calcula el brillo del color usando la fórmula W3C para el contraste
    const brightness = (299 * red + 587 * green + 114 * blue) / 1000;

    // Elige un valor de alfa que asegure un contraste adecuado
    const alpha = brightness >= 128 ? 0.7 : 1.0;

    // Crea el color para borderColor
    const borderColor = `rgba(${red},${green},${blue},${alpha})`;

    // Crea un color más transparente para backgroundColor
    const backgroundColor = `rgba(${red},${green},${blue},${alpha * 0.25})`;

    return {borderColor, backgroundColor};
}

/**
 * Función que genera un color aletorório y devuelve el color dos veces una de ellas más transparente.
 * @returns {{borderColor: string, backgroundColor: string}}
 */
function generarColoresParaGrafico2() {
    // Genera componentes de color aleatorios
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Calcula el brillo del color usando la fórmula W3C para el contraste
    const brightness = (299 * red + 587 * green + 114 * blue) / 1000;

    // Elige un valor de alfa que asegure un contraste adecuado
    const alpha = brightness >= 128 ? 0.7 : 1.0;

    // Crea el color para borderColor
    const borderColor = `rgba(${red},${green},${blue},${alpha})`;

    // Crea un color más transparente para backgroundColor
    const backgroundColor = `rgba(${red},${green},${blue},${alpha * 0.5})`;

    return {borderColor, backgroundColor};
}

/**
 * Función que crea un dataset dados los siguientes parámetros.
 * @param label
 * @param data
 * @param color
 * @param borderWidth
 * @param unit
 * @param stepped
 * @param fill
 * @returns {{backgroundColor: string, borderColor: string, data, borderWidth, label, yAxisID}}
 */
function crearDataset(label, data, colores, borderWidth, unit, stepped = false, fill = true) {
    // let semillaColor = "rgba(62,113,126,0.52)";



    return {
        label: label,
        backgroundColor: colores.backgroundColor,
        borderColor: colores.borderColor,
        borderWidth: borderWidth,
        data: data,
        yAxisID: unit,
        fill: fill,
        stepped: stepped,
    };
}

/**
 * Función para crear las escalas de los datos.
 * @param position
 * @param stack
 * @param stackWeight
 * @param label
 * @param color
 * @param type
 * @returns {{position, title: {display: boolean, text}}}
 */
function crearAxesValues(label, stack, stackWeight,color, type = 'linear', position = 'left') {

    let valores;
    if (type === 'category') {
        valores = {
            type: type,
            labels: [1, 0],
            position: position,
            stack: stack,
            stackWeight: stackWeight,
            title: {
                display: true,
                text: label,
                beginAtZero: true,
            },
            offset: true, // para evitar que se junten las gráficas
        }
    } else {
        valores = {
            type: type,
            position: position,
            stack: stack,
            stackWeight: stackWeight,
            ticks: {
                color: color
            },
            title: {
                display: true,
                text: label
            },
            offset: true,  // para evitar que se junten las gráficas
        }
    }


    return valores;
}


/**
 * Función que realiza una petición ajax para que le devuelva los datos del nodo.
 */
function obtenerDatos(item,type=0) {


    let sample_size = item.val();
    $.ajax({
        url: $('#id').data('url'), // Reemplaza con la URL de tu API o archivo JSON
        method: 'POST',
        dataType: 'json',
        data: {
            node_id: $('#id').val(),
            sample_size: $('#sample-size').val(),
            date_range: $('.date-range').val(),
        },
        success: function (data) {
            // Cuando se reciban los datos exitosamente, crea los gráficos
            console.log(data);
            crearGraficosSensores('tabla-chart', data.data_sensors);
            crearGraficosActuadores('tabla-chart-act', data.data_actuators);
            // crearGraficos('nodeLineChart',data);
        },
        error: function (error) {
            console.error('Error al obtener los datos:', error);
        }
    });
}


function crearGraficos2(view_class, data = null) {

    if ($('.' + view_class).length) {
        let datasets = [];
        let scales_values = [];

        let labels = [];
        let dataH = [];
        let dataQ = [];
        let dataP = [];

        for (let i = 1; i < 30; i++) {
            // labels.push(i);
            labels.push(i);
            for (let j = 0; j < 24; j++) {
                // labels.push(j);
                labels.push("");
            }
        }

        let qInicial = 7.20;
        let pInicial = 5.90;
        let q = 0.00250348;
        let p = 0.00205146;
        let numero = 1;

        let numeros = [7,
            8,
            9,
            10,
            11,
            12,
            13,
            17,
            18,
            19,
            20,
            21,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            41,
            42,
            43,
            44,
            45,
            56,
            57,
            58,
            59,
            60,
            61,
            66,
            67,
            68,
            69,
            80,
            81,
            82,
            83,
            84,
            85,
            86,
            90,
            91,
            92,
            93,
            104,
            105,
            106,
            107,
            108,
            109,
            110,
            114,
            115,
            116,
            117,
            128,
            129,
            130,
            131,
            132,
            133,
            134,
            138,
            139,
            140,
            141,
            152,
            153,
            154,
            155,
            156,
            157,
            158,
            162,
            163,
            164,
            165,
            176,
            177,
            178,
            179,
            180,
            181,
            182,
            186,
            187,
            188,
            189,
            200,
            201,
            202,
            203,
            204,
            205,
            206,
            210,
            211,
            212,
            213,
            224,
            225,
            226,
            227,
            228,
            229,
            230,
            224,
            225,
            226,
            227,
            228,
            229,
            230,
            224,
            225,
            226,
            227,
            228,
            229,
            230,
            234,
            235,
            236,
            237,
            248,
            249,
            250,
            251,
            252,
            253,
            254,
            258,
            259,
            260,
            261,
            272,
            273,
            274,
            275,
            276,
            277,
            278,
            282,
            283,
            284,
            285,
            296,
            297,
            298,
            299,
            300,
            301,
            306,
            307,
            308,
            309,
            320,
            321,
            322,
            323,
            324,
            325,
            326,
            330,
            331,
            332,
            333,
            344,
            345,
            346,
            347,
            348,
            349,
            350,
            354,
            355,
            356,
            357,
            368,
            369,
            370,
            371,
            372,
            373,
            374,
            378,
            379,
            380,
            381,
            392,
            393,
            394,
            395,
            396,
            397,
            398,
            402,
            403,
            404,
            405,
            416,
            417,
            418,
            419,
            420,
            421,
            422,
            426,
            427,
            428,
            429,
            440,
            441,
            442,
            443,
            444,
            445,
            446,
            450,
            451,
            452,
            453,
            464,
            465,
            466,
            467,
            468,
            469,
            470,
            474,
            475,
            476,
            477,
            488,
            489,
            490,
            491,
            492,
            493,
            494,
            498,
            499,
            500,
            501,
            512,
            513,
            514,
            515,
            516,
            517,
            518,
            522,
            523,
            524,
            525,
            536,
            537,
            538,
            539,
            540,
            541,
            542,
            546,
            547,
            548,
            549,
            560,
            561,
            562,
            563,
            564,
            565,
            566,
            570,
            571,
            572,
            573,
            584,
            585,
            586,
            587,
            588,
            589,
            590,
            594,
            595,
            596,
            597,
            608,
            609,
            610,
            611,
            612,
            613,
            614,
            618,
            619,
            620,
            621,
            632,
            633,
            634,
            635,
            636,
            637,
            638,
            642,
            643,
            644,
            645,
            656,
            657,
            658,
            659,
            660,
            661,
            662,
            666,
            667,
            668,
            669,
            680,
            681,
            682,
            683,
            684,
            685,
            686,
            690,
            691,
            692,
            693,
            704,
            705,
            706,
            707,
            708,
            709,
            710,
            714,
            715,
            716,
            717,
        ];

        labels.forEach(function () {
            dataH.push(9.9);

            if (numeros.includes(numero)) {
                dataQ.push(0);
                dataP.push(0);
            } else {
                dataQ.push(qInicial - (numero * q));
                dataP.push(pInicial - (numero * p));
            }
            numero++;
        });


        scales_values['y'] = crearAxesValues('Metros', 'graph', 1,'black');
        datasets.push(crearDataset("Profundidad (m)", dataH, "rgb(105,103,70)", 1, 'y', false, false));

        scales_values['y1'] = crearAxesValues('m³/h', 'graph2', 2,'black');
        datasets.push(crearDataset("Caudal (m³/h)", dataQ, "rgb(67,72,164)", 0.5, 'y1', false));

        scales_values['y2'] = crearAxesValues('Bar', 'graph3', 2,'black');
        datasets.push(crearDataset("Presión (bar)", dataP, "rgb(177,70,206)", 1, 'y2', false));

        scales_values['x'] = {
            title: {
                display: true,
                text: 'Días',

            },
            ticks: {
                sampleSize: 0.5
            }
        }

        $('.' + view_class).each(function (index) {
            let ctx = $(this).get(0).getContext('2d');
            let config = {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    plugins: {
                        legend: {
                            display: true
                        }
                    },
                    scales: scales_values
                }
            };
            if (myChart2) {
                myChart2.destroy();
            }

            myChart2 = new Chart(ctx, config);

            myChart2.update("none");
        });
    }
}

function rellenarDatosSensores(data, scales_values, count, datasets) {

    let seed = "rgba(62,128,199,0.5)";
    data.data.forEach(function (sensor) {

        let colores = generarColoresParaGrafico(seed);

        if (sensor.label === 'Estado') {
            // scales_values['y'+count]=crearAxesValues(sensor.unit,'graph2', 1,'linear','right');
            scales_values['y' + count] = crearAxesValues(sensor.unit, 'graph', 1,colores.borderColor);
            datasets.push(crearDataset(sensor.label + "(" + sensor.unit + ")", sensor.data, colores, 1, 'y' + count, true));
        } else {
            scales_values['y' + count] = crearAxesValues(sensor.unit, 'graph', 2,colores.borderColor);
            datasets.push(crearDataset(sensor.label + "(" + sensor.unit + ")", sensor.data,colores, 1, 'y' + count, false));
        }

        count++;
    })
}

/*
*
* Colors
*       rgba(251,108,95,0.175)
*       rgba(143,235,236,0.175)
*       rgba(211,148,251,0.175)
* */

function rellenarDatosActuators(data, scales_values, count, datasets) {
    let seed = "rgba(62,128,199,0.5)";
    data.data.forEach(function (actuator) {
        let colores = generarColoresParaGrafico(seed);
        scales_values['y' + count] = crearAxesValues(actuator.unit, 'graph', 1,'black', 'category');
        datasets.push(crearDataset(actuator.label + "(" + actuator.unit + ")", actuator.data, colores , 1, 'y' + count, true,false));

        count++;
    })
}

function crearGraficosSensores(view_class, data = null) {
    if ($('.' + view_class).length) {
        let datasets = [];
        let scales_values = [];
        let labels = data.labels;
        let count = 0;

        rellenarDatosSensores(data, scales_values, count, datasets);

        scales_values['x'] = {
            title: {
                display: true,
                text: 'Fechas',
            },
            ticks: {
                sampleSize: 2
            }
        }

        $('.' + view_class).each(function (index) {
            let ctx = $(this).get(0).getContext('2d');
            let config = {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    plugins: {
                        legend: {
                            display: true
                        },
                        decimation: {
                            enabled: true,
                            algorithm: 'lttb',
                            samples: 50,
                        },
                    },
                    scales: scales_values,
                    // showLine: false,
                    responsive:true,
                    maintainAspectRatio: false,

                }
            };

            if (myChart) {
                myChart.destroy();
            }

            myChart = new Chart(ctx, config);

            myChart.update("none");
        });
    }
}

function crearGraficosActuadores(view_class, data) {
    if ($('.' + view_class).length) {
        let datasets = [];
        let scales_values = [];
        let labels = data.labels;
        let count = 0;

        rellenarDatosActuators(data,scales_values,count,datasets);

        scales_values['x'] = {
            title: {
                display: true,
                text: 'Fechas',
            },
            ticks: {
                sampleSize: 2
            }
        }

        $('.' + view_class).each(function (index) {
            let ctx = $(this).get(0).getContext('2d');
            let config = {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    plugins: {
                        legend: {
                            display: true
                        },
                        decimation: {
                            enabled: false,
                            algorithm: 'min-max',
                        },

                    },
                    scales: scales_values,
                    // showLine: false,
                    responsive:true,
                    maintainAspectRatio: false,
                }
            };

            if (myChart3) {
                myChart3.destroy();
            }

            myChart3 = new Chart(ctx, config);

            myChart3.update("none");
        });
    }
}

function iniciar_grafico_fijo() {
    if ($('.nodeLineChart').length) {
        // Arrays para los datos de la tabla
        let labels = [];
        let dataH = [];
        let dataQ = [];
        let dataP = [];

        for (let i = 1; i < 30; i++) {
            labels.push(i);
            for (let j = 0; j < 24; j++) {
                labels.push("");
            }
        }

        let qInicial = 7.20;
        let pInicial = 5.90;
        let q = 0.00250348;
        let p = 0.00205146;
        let numero = 1;

        let numeros = [7,
            8,
            9,
            10,
            11,
            12,
            13,
            17,
            18,
            19,
            20,
            21,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            41,
            42,
            43,
            44,
            45,
            56,
            57,
            58,
            59,
            60,
            61,
            66,
            67,
            68,
            69,
            80,
            81,
            82,
            83,
            84,
            85,
            86,
            90,
            91,
            92,
            93,
            104,
            105,
            106,
            107,
            108,
            109,
            110,
            114,
            115,
            116,
            117,
            128,
            129,
            130,
            131,
            132,
            133,
            134,
            138,
            139,
            140,
            141,
            152,
            153,
            154,
            155,
            156,
            157,
            158,
            162,
            163,
            164,
            165,
            176,
            177,
            178,
            179,
            180,
            181,
            182,
            186,
            187,
            188,
            189,
            200,
            201,
            202,
            203,
            204,
            205,
            206,
            210,
            211,
            212,
            213,
            224,
            225,
            226,
            227,
            228,
            229,
            230,
            224,
            225,
            226,
            227,
            228,
            229,
            230,
            224,
            225,
            226,
            227,
            228,
            229,
            230,
            234,
            235,
            236,
            237,
            248,
            249,
            250,
            251,
            252,
            253,
            254,
            258,
            259,
            260,
            261,
            272,
            273,
            274,
            275,
            276,
            277,
            278,
            282,
            283,
            284,
            285,
            296,
            297,
            298,
            299,
            300,
            301,
            306,
            307,
            308,
            309,
            320,
            321,
            322,
            323,
            324,
            325,
            326,
            330,
            331,
            332,
            333,
            344,
            345,
            346,
            347,
            348,
            349,
            350,
            354,
            355,
            356,
            357,
            368,
            369,
            370,
            371,
            372,
            373,
            374,
            378,
            379,
            380,
            381,
            392,
            393,
            394,
            395,
            396,
            397,
            398,
            402,
            403,
            404,
            405,
            416,
            417,
            418,
            419,
            420,
            421,
            422,
            426,
            427,
            428,
            429,
            440,
            441,
            442,
            443,
            444,
            445,
            446,
            450,
            451,
            452,
            453,
            464,
            465,
            466,
            467,
            468,
            469,
            470,
            474,
            475,
            476,
            477,
            488,
            489,
            490,
            491,
            492,
            493,
            494,
            498,
            499,
            500,
            501,
            512,
            513,
            514,
            515,
            516,
            517,
            518,
            522,
            523,
            524,
            525,
            536,
            537,
            538,
            539,
            540,
            541,
            542,
            546,
            547,
            548,
            549,
            560,
            561,
            562,
            563,
            564,
            565,
            566,
            570,
            571,
            572,
            573,
            584,
            585,
            586,
            587,
            588,
            589,
            590,
            594,
            595,
            596,
            597,
            608,
            609,
            610,
            611,
            612,
            613,
            614,
            618,
            619,
            620,
            621,
            632,
            633,
            634,
            635,
            636,
            637,
            638,
            642,
            643,
            644,
            645,
            656,
            657,
            658,
            659,
            660,
            661,
            662,
            666,
            667,
            668,
            669,
            680,
            681,
            682,
            683,
            684,
            685,
            686,
            690,
            691,
            692,
            693,
            704,
            705,
            706,
            707,
            708,
            709,
            710,
            714,
            715,
            716,
            717,
        ];

        labels.forEach(function () {
            dataH.push(9.9);

            if (numeros.includes(numero)) {
                dataQ.push(0);
                dataP.push(0);
            } else {
                dataQ.push(qInicial - numero * q);
                dataP.push(pInicial - numero * p);
            }
            numero++;
        });

        $('.nodeLineChart').each(function (index) {
            let ctx = $(this).get(0).getContext('2d');
            let config = {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "Profundidad (m)",
                            backgroundColor: "rgba(136, 136, 136, 0.31)",
                            borderColor: "rgba(136, 136, 136, 0.7)",
                            pointBorderColor: "rgba(136, 136, 136, 0.7)",
                            pointBackgroundColor: "rgba(136, 136, 136, 0.7)",
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: "rgba(220,220,220,1)",
                            fill: false,
                            tension: 0.3,
                            stepped: 'middle', // Cambiado de 'true' a 'middle'
                            pointBorderWidth: 1,
                            data: dataH,
                            yAxisID: 'y' // Asignar el ID de la escala del eje y
                        },
                        {
                            label: "Caudal (m³/h)",
                            backgroundColor: "rgba(38, 185, 154, 0.21)",
                            borderColor: "rgba(38, 185, 154, 0.4)",
                            pointBorderColor: "rgba(38, 185, 154, 0.4)",
                            pointBackgroundColor: "rgba(38, 185, 154, 0.4)",
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: "rgba(220,220,220,1)",
                            pointBorderWidth: 0.5,
                            tension: 0, // Cambiado de 0
                            stepped: 'middle', // Cambiado de 'true' a 'middle'
                            data: dataQ,
                            yAxisID: 'y1' // Asignar el ID de la escala del eje y
                        },
                        {
                            label: "Presión (bar)",
                            backgroundColor: "rgba(3, 88, 106, 0.3)",
                            borderColor: "rgba(3, 88, 106, 0.70)",
                            pointBorderColor: "rgba(3, 88, 106, 0.70)",
                            pointBackgroundColor: "rgba(3, 88, 106, 0.70)",
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: "rgba(151,187,205,1)",
                            pointBorderWidth: 1,
                            tension: 0, // Cambiado de 0
                            stepped: 'middle', // Cambiado de 'true' a 'middle'
                            data: dataP,
                            yAxisID: 'y2' // Asignar el ID de la escala del eje y
                        }
                    ]
                },
                options: {
                    plugins: {
                        legend: {
                            display: true
                        }
                    },
                    scales: {
                        y: {
                            position: 'left',
                            title: {
                                display: true,
                                text: 'Metros'
                            },
                            grid: {
                                drawOnChartArea: false, // only want the grid lines for one axis to show up
                            },
                        },
                        y1: {
                            position: 'right',
                            title: {
                                display: true,
                                text: 'm³/h'
                            },

                        },
                        y2: {
                            position: 'right',
                            title: {
                                display: true,
                                text: 'Bar'
                            },
                            grid: {
                                drawOnChartArea: false, // only want the grid lines for one axis to show up
                            },
                        },
                        x: {
                            title: {
                                display: true,
                                text: 'Días',

                            },
                            ticks: {
                                stepSize: 2
                            }
                        }
                    }
                }
            };

            let lineChart2 = new Chart(ctx, config);

            lineChart2.update();

        });
    }
}

function prueba() {

    // Datos de ejemplo
    let data = {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
        datasets: [
            {
                label: 'Flujo (m³/h)',
                borderColor: 'rgba(75, 192, 192, 1)',
                data: [10, 15, 13, 17, 12, 20],
                yAxisID: 'y1',
            },
            {
                label: 'Presión (Bar)',
                borderColor: 'rgba(255, 99, 132, 1)',
                data: [5, 8, 10, 7, 9, 6],
                yAxisID: 'y2',
            },
            {
                label: 'Altura (Metros)',
                borderColor: 'rgba(54, 162, 235, 1)',
                data: [2, 4, 6, 8, 10, 12],
                yAxisID: 'y3',
            }
        ]
    };


// Configuración de ejes con diferentes unidades
    let options = {
        scales: {
            x: {
                type: 'category',
            },
            y1: {
                id: 'm3h',
                position: 'left',
                title: {
                    display: true,
                    text: 'Flujo (m³/h)',
                },
            },
            y2: {
                id: 'bar',
                position: 'right',
                title: {
                    display: true,
                    text: 'Presión (Bar)',
                },
            },
            y3: {
                id: 'metros',
                position: 'right',
                title: {
                    display: true,
                    text: 'Altura (Metros)',
                },
                grid: {
                    drawOnChartArea: false,
                },
            },

        }
    };

    let ctx = document.getElementById('lineChart').getContext('2d');

    let lineChart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: options
    });

    lineChart.update('none');

}

function init_charts() {

    // crearGraficos();
    // crearGraficos2('tabla-chart2');
    // obtenerDatos(200);

}


(function ($) {
    "use strict";

    $(document).ready(function () {

        function changeSampleSize(item,selector=0) {
            obtenerDatos(item,selector);
        }

        if ($('.chart-sample-size').length) {


            $('.chart-sample-size').on('change', function () {
                changeSampleSize($(this));
                // myChart.options.plugins.decimation.algorithm = 'lttb';
                // myChart.options.plugins.decimation.enabled = true;
                // myChart.options.plugins.decimation.samples = 50;
                // myChart.update();
            });
            // $('.chart-sample-size').change();
        }

        if ($('.date-range').length){
            $('.date-range').on('change',function () {
                changeSampleSize($(this))

            })
            $('.date-range').change();
        }


        // if ($('.btn-100').length) {
        //
        //     $('.btn-100').on( "click",function () {
        //         $(".charts").removeClass("col-md-6").addClass("col-md-12");
        //
        //         myChart.resize(1534,767);
        //         myChart3.resize(1534,767);
        //     })
        // }
        //
        // if ($('.btn-50').length) {
        //     $('.btn-50').on( "click",function () {
        //         $(".charts").removeClass("col-md-12").addClass("col-md-6");
        //         myChart.resize(756,378);
        //         myChart3.resize(756,378);
        //     })
        //
        // }
    });
})
(jQuery);
