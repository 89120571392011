/**
 * timetable.js
 * Created by PULSARINARA\d.monfort on 24/02/22.
 */
(function ($) {
    "use strict";
    $(document).ready(function () {
        //Pruebas Timeline

        let listItems = [],
            maxList;
        //mostrar la timeline
        // se mirará dejarla visible todo el rato
        $(".show-timeline").click(function () {
            // $(this).hide();

            $("#myTimeline").show();
            $("#myTimeline2").show();
            listItems = [];


            maxList = window.arrayActuators.length;
            let   actuators = window.arrayActuators;

            console.log(actuators);

            for (let incr = 0; incr < maxList; incr++) {
                listItems.push("<span> " + Object.values(actuators[incr])[0] + "</span>");


            }

            reloadTimeline();
            reloadTimeline2();


            //Opciones específicas de cada timeline
            //   actualiza los datos si se han modificado
            let new_options_on = {
                headline: {
                    display: true,
                    title  : "Secuencia de Encendido",
                    range  : false,
                    locale : "es-ES",
                    format : {hour12: false}
                },
                sidebar: {

                    list   : listItems //  an array of item
                },
            }

            //Opciones específicas de cada timeline
            //     actualiza los datos si se han modificado
            let new_options_off = {
                sidebar: {
                    list   : listItems //  an array of item
                },
            }

            $("#myTimeline").Timeline('reload', new_options_on , (elm,opts,usrdata) => {
                console.log( usrdata.msg ) // show "Changed Timeline Type!" in console
            }, {msg:'changed Timeline sidebar list'} );

            $("#myTimeline2").Timeline('reload', new_options_off , (elm,opts,usrdata) => {
                console.log( usrdata.msg ) // show "Changed Timeline Type!" in console
            }, {msg:'changed Timeline2 sidebar list'} );


            $('#myTimeline2').Timeline('alignment', 'begin')
            for (let i = 0; i< maxList; i++){

                // let event =createEvents(i);
                // $("#myTimeline").Timeline('addEvent', event)

                let id = Object.values(actuators[i])[1];


                $('#myTimeline').Timeline('addEvent', [
                        {id:id,start:'2022-01-03 11:47',end:'2022-01-03 11:50',row:1,label:'Add Event',content:'test test test...'},
                        {id:id+1,start:'2022-01-03 11:48',end:'2022-01-03 11:50',row:2,label:'Add Event 2',content:'test2 test2 test2...'}
                    ]
                )


                // $('#myTimeline').Timeline('addEvent', [
                //     {id: id,
                //         start: getModDateString(new Date(), {h: 0, min: 0, sec: 0}),
                //         end : getModDateString(new Date(), {h: 0, min: 8, sec: 0}),
                //         row : i,
                //         label : 'Evento '+i,
                //         content : 'Pruebas'
                //     },
                //     function( elm, opts, usrdata, addedEvents ){
                //         console.log( usrdata.message ); // show "Added Events!" in console
                //     },
                //     { message: 'Added Events!' }
                // ])
            }
            //console.log(listItems);
        });



        let reloadTimeline = function () {
            // var new_event = "<li data-timeline-node=\"{ start:'2022-03-01 11:49:30',end:'2022-03-01 11:55:44',row:3,bgColor:'#d8680f',color:'#FFFFFF',content:'Event Here' }\">Event Label</li>";
            // $(".timeline-events").append(new_event);


            window.tlOpts = {
                type         : "bar",
                scale        : "minutes",
                startDatetime: getModDateString(new Date(), {h: 0, min: 0, sec: 0}),

                endDatetime: getModDateString(new Date(), {h: 0, min: 6, sec: 0}),

                // rows         : 3,
                rows    : "auto",
                headline: {
                    display: true,
                    title  : "Secuencia de apagado",
                    range  : false,
                    locale : "es-ES",
                    format : {hour12: false}
                },

                sidebar: {
                    sticky : true,

                    list   : listItems //  an array of item
                },

                ruler: {

                    top: {

                        lines      : ["minute"],
                        height    : 20,
                        fontSize  : 9,
                        color     : "#000000",
                        background: "#FFFFFF",
                        locale    : "es-ES",
                        // format    : {custom: "HH:MM:SS"}

                        // format    : { hour12: false }
                    }
                },

                footer   : {
                    display: true,
                    content: "",
                    range  : false,
                    locale : "es-ES",
                    format : {hour12: false}
                },
                eventMeta: {
                    display: false,
                    scale  : "hour",
                    locale : "en-US",
                    format : {hour12: false},
                    content: ""
                },
                eventData: [],

                effects  : {
                    presentTime        : true,
                    hoverEvent         : true,
                    stripedGridRow     : true,
                    horizontalGridStyle: "dotted",
                    verticalGridStyle  : "solid"
                },

                colorScheme: { // Added new option since v2.0.0
                    event          : {
                        text      : "#ffffff",
                        border    : "#6C757D",
                        background: "#1b46ce"
                    },
                    hookEventColors: () => null, // Added instead of merging setColorEvent of PR#37 since v2.0.0
                },

                hideScrollbar  : false,
                shift          : true,
                range          : 12,
                // rows: 3,
                rowHeight      : 40,
                width          : "auto",
                height         : "auto",
                minGridSize    : 100,
                marginHeight   : 1,
                // rangeAlign: "latest",
                rangeAlign     : "center",
                loader         : "default",
                // loadingMessage : "Cargando..",
                storage        : "session",
                reloadCacheKeep: true,
                // reloadCacheKeep: false,
                // zoom: true,
                zoom           : false,
                wrapScale      : true,
                firstDayOfWeek : 0,
                engine         : "canvas",
                disableLimitter: false,
                debug          : false


            }

            $("#myTimeline").Timeline(
                tlOpts
            );
            // $(".jqtl-ruler-top").hide();
        };




        let reloadTimeline2 = function () {
            var new_event = "<li data-timeline-node=\"{ start:'2022-02-17 12:01',end:'2022-02-17 12:05',row:3,content:'Event Here' }\">Event Label</li>";
            $(".timeline-events").append(new_event);


            $("#myTimeline2").Timeline(
                tlOpts
            );
            //$(".jqtl-ruler-top").hide();
        };




    });

    function createEvents(num) {
        // Helper to randomly generate a specified number of events
        console.log("Entra")
        let nowDt = new Date(tlOpts.startDatetime),
            _evts = [],
            _max = num || 1,
            _startId = lastEventId() + 1,
            _startDt, _endDt, _row, i

        for (i = 0; i < _max; i++) {
            _startDt = getDateString(nowDt.getDate())
            _endDt = getDateString(nowDt.getTime() + ((Math.floor(Math.random() * 7) + 1) * 24 * 60 * 60 * 1000))
            _row = Math.floor(Math.random() * tlOpts.rows) + 1
            _evts.push({
                start  : _startDt,
                end    : _endDt,
                row    : _row,
                label  : 'Created new event (' + (_startId + i) + ')',
                content: 'This is an event added by the addEvent method.'
            })
        }
        return _evts
    }

    function getDateArray(date) {
        // Helper to get each elements of Date object as an array
        let _dt = date instanceof Date ? date : new Date(date)

        return [_dt.getFullYear(), _dt.getMonth(), _dt.getDate(), _dt.getHours(), _dt.getMinutes(), _dt.getSeconds(), _dt.getMilliseconds()]
    }

    function getDateString(date) {
        // Helper to get Date object as a string of "Y-m-d H:i:s" format
        let _dt = getDateArray(date)

        //return _dt[0] +'-'+ (_dt[1] + 1) +'-'+ _dt[2] +' '+ _dt[3] +':'+ _dt[4] +':'+ _dt[5]
        return `${_dt[0]}-${_dt[1] + 1}-${_dt[2]} ${_dt[3]}:${_dt[4]}:${_dt[5]}`
    }

    function getModDateString(date, mods) {
        // Helper to get the date string after updating the date with the specified amount time.
        let baseDt = date instanceof Date ? new Date(date.getTime()) : new Date(date),
            getRandomInt = function (min, max) {
                min = Math.ceil(min)
                max = Math.floor(max)
                return Math.floor(Math.random() * (max - min)) + min
            },
            found

        if (mods && typeof mods === 'object') {
            for (let _key in mods) {
                found = null
                switch (true) {
                    case /^y(|ears?)$/i.test(_key):
                        if (typeof mods[_key] === 'string' && /^(-|\+)\d+$/i.test(mods[_key])) {
                            found = mods[_key].match(/^(-|\+)(\d+)$/)
                            if (found[1] === '-') {
                                baseDt.setFullYear(baseDt.getFullYear() - Number(found[2]))
                            } else {
                                baseDt.setFullYear(baseDt.getFullYear() + Number(found[2]))
                            }
                        } else if ('rand' === mods[_key]) {
                            baseDt.setFullYear(getRandomInt(1, new Date().getFullYear()))
                        } else {
                            baseDt.setFullYear(Number(mods[_key]))
                        }
                        break
                    case /^mon(|ths?)$/i.test(_key):
                        if (typeof mods[_key] === 'string' && /^(-|\+)\d+$/i.test(mods[_key])) {
                            found = mods[_key].match(/^(-|\+)(\d+)$/)
                            if (found[1] === '-') {
                                baseDt.setMonth(baseDt.getMonth() - Number(found[2]))
                            } else {
                                baseDt.setMonth(baseDt.getMonth() + Number(found[2]))
                            }
                        } else if ('rand' === mods[_key]) {
                            baseDt.setMonth(getRandomInt(0, 11))
                        } else {
                            baseDt.setMonth(Number(mods[_key]) == 12 ? 11 : Number(mods[_key]))
                        }
                        break
                    case /^d(|ays?)$/i.test(_key):
                        if (typeof mods[_key] === 'string' && /^(-|\+)\d+$/i.test(mods[_key])) {
                            found = mods[_key].match(/^(-|\+)(\d+)$/)
                            if (found[1] === '-') {
                                baseDt.setDate(baseDt.getDate() - Number(found[2]))
                            } else {
                                baseDt.setDate(baseDt.getDate() + Number(found[2]))
                            }
                        } else if ('rand' === mods[_key]) {
                            baseDt.setDate(getRandomInt(1, 31))
                        } else {
                            baseDt.setDate(Number(mods[_key]))
                        }
                        break
                    case /^h(|ours?)$/i.test(_key):
                        if (typeof mods[_key] === 'string' && /^(-|\+)\d+$/i.test(mods[_key])) {
                            found = mods[_key].match(/^(-|\+)(\d+)$/)
                            if (found[1] === '-') {
                                baseDt.setHours(baseDt.getHours() - Number(found[2]))
                            } else {
                                baseDt.setHours(baseDt.getHours() + Number(found[2]))
                            }
                        } else if ('rand' === mods[_key]) {
                            baseDt.setHours(getRandomInt(0, 23))
                        } else {
                            baseDt.setHours(Number(mods[_key]))
                        }
                        break
                    case /^min(|utes?)$/i.test(_key):
                        if (typeof mods[_key] === 'string' && /^(-|\+)\d+$/i.test(mods[_key])) {
                            found = mods[_key].match(/^(-|\+)(\d+)$/)
                            if (found[1] === '-') {
                                baseDt.setMinutes(baseDt.getMinutes() - Number(found[2]))
                            } else {
                                baseDt.setMinutes(baseDt.getMinutes() + Number(found[2]))
                            }
                        } else if ('rand' === mods[_key]) {
                            baseDt.setMinutes(getRandomInt(0, 59))
                        } else {
                            baseDt.setMinutes(Number(mods[_key]))
                        }
                        break
                    case /^s(|(ec|onds?))$/i.test(_key):
                        if (typeof mods[_key] === 'string' && /^(-|\+)\d+$/i.test(mods[_key])) {
                            found = mods[_key].match(/^(-|\+)(\d+)$/)
                            if (found[1] === '-') {
                                baseDt.setSeconds(baseDt.getSeconds() - Number(found[2]))
                            } else {
                                baseDt.setSeconds(baseDt.getSeconds() + Number(found[2]))
                            }
                        } else if ('rand' === mods[_key]) {
                            baseDt.setSeconds(getRandomInt(0, 59))
                        } else {
                            baseDt.setSeconds(Number(mods[_key]))
                        }
                        break
                    default:
                        break
                }
            }
        }
        return getDateString(baseDt);
    }

    function lastEventId() {
        let $evtElms = $(document).find('.jqtl-event-node'),
            evtIds   = []

        if ( $evtElms.length > 0 ) {
            $evtElms.each(function(idx, elm) {
                evtIds.push( parseInt( elm.id.replace('evt-', ''), 10 ) )
            })
            return Math.max( ...evtIds )
        } else {
            return 0
        }
    }

})(jQuery);


//Coger la fecha actual


/*
 * Dispatcher
 */
// if ( document.readyState === 'complete' || ( document.readyState !== 'loading' && ! document.documentElement.doScroll ) ) {
//     init()
// } else
// if ( document.addEventListener ) {
//     document.addEventListener( 'DOMContentLoaded', init, false )
// } else {
//     window.onload = init
// }


